<section class="section-contact">
  <div class="container px-5 px-md-0">
    <div class="row justify-content-center">
      <div class="col-12">
        <h1 class="mb-3">
          {{ "contact.title" | translate }}
          <b>{{ "contact.titleTwo" | translate }} </b>
        </h1>

        <p class="mb-3 mb-md-5">
          {{ "contact.text" | translate }}
        </p>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 col-lg-6">
        <form [formGroup]="form" class="section__form">
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="Input">
                <input formControlName="fullName" type="text" id="input" class="Input-text" placeholder="{{ 'form.fullName' | translate }}" />
                <div class="mt-2" *ngIf="
                    form.get('fullName').errors && form.get('fullName').touched
                  ">
                  <p class="texts__error" *ngIf="form.get('fullName').hasError('required')">
                    {{ "form.requiredField" | translate }}
                  </p>

                  <p class="texts__error mt-2" *ngIf="form.get('fullName').hasError('maxlength')">
                    {{ "form.maximun40Characters" | translate }}
                  </p>

                  <p class="texts__error mt-2" *ngIf="form.get('fullName').hasError('pattern')">
                    {{ "form.onlyLetters" | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center mb-3">
            <div class="col-12 col-md-6">
              <div class="Input">
                <input formControlName="email" type="email" id="input" class="Input-text" placeholder="{{ 'form.email' | translate }}" />
                <div class="mt-2" *ngIf="form.get('email').errors && form.get('email').touched">
                  <p class="texts__error" *ngIf="form.get('email').hasError('required')">
                    {{ "form.requiredField" | translate }}
                  </p>

                  <p class="texts__error mt-2" *ngIf="form.get('email').hasError('email')">
                    {{ "form.invalidEmail" | translate }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="Input">
                <input formControlName="phone" type="text" id="input" class="Input-text" placeholder="{{ 'form.phone' | translate }}" />
                <div class="mt-2" *ngIf="form.get('phone').errors && form.get('phone').touched">
                  <p class="texts__error" *ngIf="form.get('phone').hasError('required')">
                    {{ "form.requiredField" | translate }}
                  </p>

                  <p class="texts__error mt-2" *ngIf="form.get('phone').hasError('pattern')">
                    {{ "form.invalidNumber" | translate }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="Input">
                <textarea formControlName="message" id="input" class="Input-text" rows="5" placeholder=" {{ 'form.message' | translate }}"></textarea>
                <div class="mt-2" *ngIf="
                    form.get('message').errors && form.get('message').touched
                  ">
                  <p class="texts__error" *ngIf="form.get('message').hasError('required')">
                    {{ "form.requiredField" | translate }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 text-center" style="color: white;" *ngIf="sended">
              {{ "form.sent" | translate }}
            </div>
            <div class="col-12 text-center">
              <button (click)="onFormSubmit($event)" type="submit" class="btn" style="cursor: pointer">
                {{ "form.btnSend" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row justify-content-center mt-4">
      <div class="col-12 col-lg-7">
        <div class="row no-gutters justify-content-center">
          <div class="col-12 col-md-6">
            <div class="section-contact__info ml-lg-5 mb-4 mb-md-0">
              <img src="assets/images/location-icon.svg" alt="" />
              <p class="text-left">
                <b> {{ "contact.address" | translate }}:</b>
                <br />
                AV Samuel Lewis Torre Omega, Suite 5D Panama City, Panama
              </p>
            </div>
          </div>
          <div class="col-1 d-none d-md-block">
            <div class="line">
              <img src="assets/images/border-line.svg" alt="" />
            </div>
          </div>
          <div class="col-12 col-md-5">
            <!-- <div class="section-contact__info ml-lg-4">
              <img src="assets/images/phone-icon.svg" alt="" />
              <p class="text-left">
                <b>{{ "contact.phone" | translate }}:</b>
                <br />
                +506 7131 9493
              </p>
            </div>

            <div class="section-contact__info ml-lg-4 p-3">
              <p>
                <a href="https://api.whatsapp.com/send?phone=50671319493&text=%C2%A1Hola!%20Me%20gustar%C3%ADa%20recibir%20informaci%C3%B3n%20para%20obtener%20mi%20propio%20casino%20en%20l%C3%ADnea.%20%C2%A1Por%20favor!" target="_blank"> <button
                    style="background-color: #25D366; height: 48px; width: max-content; border-radius: 5px; padding-left: 15px; padding-right: 15px; cursor: pointer">
                    <img src="assets/images/whatsapplogo.svg" style="height: 32px; vertical-align: middle; padding: 0;"
                      alt="" /> {{ "contact.subtitle" | translate }}</button> </a>
              </p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>