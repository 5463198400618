<div class="line --mobil d-md-none"></div>
<footer>
  <div class="container">
    <div class="row align-items-center">
      <div class="d-none d-sm-inline col-lg-2">
        <a (click)="scrollTop()" routerLink="/" class="logo">
          <img width="244" src="assets/images/logo-rgt.png" alt="RGT" />
        </a>
      </div>

      <div class="d-block d-sm-none" style="min-width: 100%">
        <a (click)="scrollTop()" routerLink="/" class="logo">
          <img width="208" src="assets/images/logo-rgt.png" alt="RGT" />
        </a>
      </div>

      <div class="col-12 d-md-none">
        <span class="text px-20">
          {{ "footer.text" | translate }}
        </span>
      </div>
      <div class="col-lg-7 d-none d-md-block pl-p">
        <span class="text"> {{ "footer.text" | translate }} </span>
      </div>
      <div class="col-lg-3">
        <ul class="social">
          <li>
            <a (click)="scrollTop()" routerLink="contact"><img src="assets/images/email-footer-icon.png" alt="" /></a>
          </li>
          <li>
            <a href="https://twitter.com/rgtglobal" target="_blank"><img src="assets/images/payment/social/tw.svg" alt="" /></a>
          </li>
          <!--  <li>
            <a href="https://t.me/rgtglobal" target="_blank"><img src="assets/images/payment/social/tel.svg" alt="" /></a>
          </li>
          <li>
            <a href="https://discord.gg/RqyEdJq" target="_blank"><img src="assets/images/payment/social/discord.svg" alt="" /></a>
          </li>
          <br />
          <li>
            <a href="https://medium.com/rgtglobal" target="_blank"><img src="assets/images/medium-footer-icon.png" alt="" /></a>
          </li>
          <li>
            <a href="https://github.com/rgtglobal" target="_blank"><img src="assets/images/github-footer-icon.png" alt="" /></a>
          </li>
          <li>
            <a href="https://www.youtube.com/c/rgtglobal" target="_blank"><img src="assets/images/youtube-footer-icon.png" alt="" /></a>
          </li> -->
          <!--    <li>
            <a href="https://www.linkedin.com/company/rgtglobal/" target="_blank"><img src="assets/images/payment/social/link.svg" alt="" /></a>
          </li> -->
          <!-- <li>
            <a href="https://api.whatsapp.com/send?phone=50671111538&text=%C2%A1Hola!%20Me%20gustar%C3%ADa%20recibir%20informaci%C3%B3n%20para%20obtener%20mi%20propio%20casino%20en%20l%C3%ADnea.%20%C2%A1Por%20favor!" target="_blank"><img src="assets/images/whatsapplogo.svg" alt="" /></a>
          </li> -->
          <!-- <li class="last">
            <span class="text d-md-none">{{
              "footer.licensed" | translate
            }}</span>
            <div class="movil-l" id="apg-bf044ca1-cc16-441e-88ce-6f506b905484" data-apg-seal-id="bf044ca1-cc16-441e-88ce-6f506b905484" data-apg-image-size="128" data-apg-image-type="basic-small"></div>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
  <div class="line"></div>
  <div class="container" id="containerLM">
    <span class="text text-center-footer cp-r px-md-0">
      Copyright RGT Global Inc. © {{ currentYear }}.
      <br class="d-md-none" />

      <a (click)="openDialogTerms()" class="d-none d-sm-inline">
        {{ "footer.termsService" | translate }}
      </a>
      <a (click)="openDialogTerms()" class="d-inline d-sm-none">{{ "footer.terms" | translate }}
      </a>
      /
      <a (click)="openDialogPrivacy()" class="d-none d-sm-inline">{{ "footer.privacyPolicy" | translate }}
      </a>
      <a (click)="openDialogPrivacy()" class="d-inline d-sm-none">{{
        "footer.privacy" | translate
        }}</a>
      /
      <a routerLink="contact" target="_blank">{{
        "footer.support" | translate
        }}</a>
      / <a (click)="openDialogQuestions()">FAQ</a>
    </span>
  </div>
</footer>