import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
  d = document;
  constructor(public router: Router) {}

  teams: any = [
    {
      name: "Pablo Quiros, MSc",
      position: "about.general",
      email: "gm@rgt.com",
      icon: "general-icon.svg",
    },
    {
      name: "Charles Moscoe",
      position: "about.sales",
      email: "sales@rgt.com",
      icon: "sales-icon.svg",
      id: "corbata",
    },
    {
      name: "Alex Villalobos, MBA ",
      position: "about.financial",
      email: "billing@rgt.com",
      icon: "dola-icon.svg",
    },
    {
      name: "Carlos Ramirez",
      position: "about.it",
      email: 'it@rgt.com <br class="d-block d-sm-none" /> &nbsp;',
      icon: "operations-icon.svg",
    },
    {
      name: "Carlos Fernández",
      position: "about.projectManager",
      email: "carlos@rgt.com",
      icon: "folder-icon.svg",
    },
    {
      name: "Gabriel Aguilar",
      position: "about.blockchain",
      email: "gabriel@rgt.com",
      icon: "blockchain-icon.svg",
    },
    {
      name: "Anthony Diaz",
      position: "about.back",
      email: "anthony@rgt.com",
      icon: "terminal-icon.svg",
    },
    {
      name: "Andres Fernández",
      position: "about.stack",
      email: "andres@rgt.com",
      icon: "code-icon.svg",
    },
    {
      name: "Jesús González",
      position: "about.front",
      email: "jesus@rgt.com",
      icon: "pc-code-icon.svg",
    },
    {
      name: " Jonathan Rincones",
      position: "about.front",
      email: "jonathan@rgt.com",
      icon: "pc-code-icon.svg",
    },
    {
      name: "Steven Sucre",
      position: "about.games",
      email: "steven@rgt.com",
      icon: "777-icon.svg",
    },
    {
      name: "Sofia Barreto",
      position: "about.legal",
      email: "sofia@rgt.com",
      icon: "balance-icon.svg",
    },
    {
      name: "Henry Diaz",
      position: "about.desing",
      email: "henry@rgt.com",
      icon: "pincel-icon.svg",
    },
    {
      name: "Steve Stanfield",
      position: "about.marketing",
      email: "news@rgt.com",
      icon: "marketing-icon.svg",
    },
    {
      name: "Alfredo Carmona",
      position: "about.trading",
      email: "alfredoc@rgt.com",
      icon: "marketing-icon.svg",
    },
    {
      name: "Julieta Massaguer",
      position: "about.account",
      email: "julie@rgt.com",
      icon: "marketing-icon.svg",
    },
    {
      name: "Sulbeirys Torres",
      position: "about.qa",
      email: "sulbe@rgt.com",
      icon: "lupa-icon.svg",
    },
    {
      name: "Daniela Visciglio",
      position: "about.client",
      email: "daniela@rgt.com",
      icon: "headphone-icon.svg",
    },
    {
      name: "Jaritza Zambrano",
      position: "about.client",
      email: "jaritza@rgt.com",
      icon: "headphone-icon.svg",
    },
    {
      name: "Laura Rosas",
      position: "about.client",
      email: "laura@rgt.com",
      icon: "headphone-icon.svg",
    },
  ];

  ngOnInit() {
    let header = document.getElementById("header");
    header.classList.remove("--not-found");
    header.style.display = "flex";
  }

  scrollTop() {
    this.router.navigate(["/gamecloud"]);
    this.d.body.scrollTop = 0;
    this.d.documentElement.scrollTop = 0;
  }
}
