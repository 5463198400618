<!-- <section class="headings px-0">
  <div class="container">
    <div class="row justify-content-center justify-content-md-start">
      <div class="col-12 col-md-6 col-lg-5">
        <h1 style="text-align: left" class="mb-3 pxp px-3 px-md-0">
          {{ "home.titleHeader" | translate }}
        </h1>
        <p class="pxp px-3 px-md-0 left">
          {{ "home.paragraphHeader" | translate }}
        </p>
        <div class="left-a">
          <a routerLink="/gamecloud" class="btn q--lower mt-4 mt-xl-5">
            {{ "home.btnHeader" | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="headings --internal-pages --bg-two pb-4 pb-lg-5" style="height: auto">
  <h1 class="pt-1 pt-md-0">
    {{ "home.allProductTitle" | translate }}
  </h1>
</section>

<section>
  <div class="container">
    <div class="row mt-5 mb-3">
      <div class="col-12 col-md-6 col-lg-4 px-5 px-md-0 mb-3">
        <img src="assets/images/lapto-home.png" alt="" />
      </div>
      <div class="col-12 col-md-6 col-lg-8 pl-md-4 pl-lg-5 pr-lg-0">
        <h2 class="mt-md-4 pb-3">

        </h2>
        <p>
          {{ "home.allProductText" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="betting">
  <div class="container">
    <div class="row align-items-md-center">
      <div class="col-12 pb-3 pb-md-0 col-md-6 col-lg-7 pr-lg-1">
        <div class="row">
          <div class="col-12">
            <h2 class="pb-3">{{ "home.bettingTitle" | translate }}</h2>

            <div class="col-12 px-2">
              <p class="mb-4">
                {{ "home.bettingText" | translate }}
              </p>
              <p>
                {{ "home.bettingTextTwo" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-5 left-p-g">
        <div class="row">
          <div class="col-12 mb-2">
            <a routerLink="/gamecloud-page">
              <div class="card">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img class="pt-1 pb-2 pb-lg-3 img-game" src="assets/images/gamecloud-home.png" alt="" />
                    <p>{{ "home.bettingGamecloud" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img class="d-md-none" src="assets/images/gamecloud-web.png" alt="" />
                    <img class="d-none d-md-block" src="assets/images/gamecloud-web-des.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img class="" src="assets/images/arrow-left-white.svg" alt="" />
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="col-12 mb-2">
            <a routerLink="/casino-hosting">
              <div class="card">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img class="pb-2 img-ch" src="assets/images/casino-hosting-home.png" alt="" />
                    <p>{{ "home.bettingCasinoHosting" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/casino-hosting-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img class="" src="assets/images/arrow-left-white.svg" alt="" />
                  </div>
                </div>
              </div>
            </a>
          </div>
          <!--
          <div class="col-12 mb-2">
            <a routerLink="/cryptobet">
              <div class="card">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img
                      class="pt-1 pt-md-2 pb-2 img-cb"
                      src="assets/images/cryptobet-home.png"
                      alt=""
                    />
                    <p>{{ "home.bettingCryptobet" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img
                      class=""
                      src="assets/images/cryptobet-web.png"
                      alt=""
                    />
                  </div>

                  <div class="card__img-two">
                    <img
                      class=""
                      src="assets/images/arrow-left-white.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
          </div> -->

          <div class="col-12 mb-2">
            <a routerLink="/casino-license">
              <div class="card">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img class="pt-2 pt-md-2 pb-2 pb-lg-3 img-cl" src="assets/images/casino-license-home.png" alt="" />
                    <p>{{ "home.bettingCasinoLicense" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/casinol-icense-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img class="" src="assets/images/arrow-left-white.svg" alt="" />
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="col-12 mb-2">
            <a routerLink="/leviathan">
              <div class="card">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img class="pb-2 pb-lg-2 img-l" src="assets/images/leviathan-logo.png" alt="" />
                    <p>{{ "home.bettingLeviathan" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/leviathan-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img class="" src="assets/images/arrow-left-white.svg" alt="" />
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="col-12 mb-2">
            <a routerLink="/buycasinos">
              <div class="card">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img class="pb-2 pb-lg-3 img-l" src="assets/images/buyscasinos-logo.png" alt="" />
                    <p>{{ "home.bettingBuyCasinos" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/buycasinos-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img class="" src="assets/images/arrow-left-white.svg" alt="" />
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="defi">
  <div class="container">
    <div class="row align-items-md-center">
      <div class="col-12 pb-3 col-md-6 col-lg-7 pb-md-0 order-md-1">
        <div class="row">
          <div class="col-12">
            <h2 class="pb-3">{{ "home.fintechTitle" | translate }}</h2>

            <div class="col-12 px-2">
              <p class="mb-3">
                {{ "home.fintechText" | translate }}
              </p>
              <p class="mb-3">
                {{ "home.fintechTextTwo" | translate }}
              </p>
              <p>
                {{ "home.fintechTextThree" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-5 left-p-g order-md-0">
        <div class="row">
          <div class="col-12 mb-2">
            <a routerLink="/tpay">
              <div class="card --white">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img
                      class="pt-2 pb-3 pb-md-2 img-tpay"
                      src="assets/images/tpay-home.png"
                      alt=""
                    />
                    <p>{{ "home.fintechTpay" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/tpay-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img src="assets/images/arrow-left-purple.svg" alt="" />
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="col-12 mb-2">
            <a routerLink="/efin">
              <div class="card --white">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img
                      class="pt-2 pb-3 pb-md-2 img-efin-logo"
                      src="assets/images/efin-home.png"
                      alt=""
                    />
                    <p>
                      {{ "home.fintechEfin" | translate }}
                    </p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/efin-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img
                      class=""
                      src="assets/images/arrow-left-purple.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="col-12 mb-2">
            <a routerLink="/cointeller">
              <div class="card --white">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img
                      class="pt-2 pb-3 pb-md-2 img-ct"
                      src="assets/images/cointeller-home.png"
                      alt=""
                    />
                    <p>{{ "home.fintechCointeller" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/cointeller-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img
                      class=""
                      src="assets/images/arrow-left-purple.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="col-12 mb-2">
            <a routerLink="/infinitesimal">
              <div class="card --white">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img
                      class="pt-2 pb-3 pb-md-2 img-infi"
                      src="assets/images/infinitesimal-home.png"
                      alt=""
                    />
                    <p>{{ "home.fintechInfinitesimal" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/infinitesimal-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img
                      class=""
                      src="assets/images/arrow-left-purple.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="col-12 mb-2">
            <a routerLink="/rgt-prime">
              <div class="card --white">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img
                      class="pt-2 pb-3 pb-md-2 img-rgt-prime"
                      src="assets/images/rgt-premium-home.png"
                      alt=""
                    />
                    <p>{{ "home.fintechRGTPrime" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/blast-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img
                      class=""
                      src="assets/images/arrow-left-purple.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="col-12 mb-2">
            <a routerLink="/blocksize">
              <div class="card --white">
                <div class="card__body">
                  <div class="card__description pr-2">
                    <img
                      class="pt-2 pb-3 pb-md-2 img-rgt-prime"
                      src="assets/images/blocksize-logo-web.png"
                      alt=""
                    />
                    <p>{{ "home.fintechBlocksize" | translate }}</p>
                  </div>

                  <div class="card__img">
                    <img src="assets/images/data-security-web.png" alt="" />
                  </div>

                  <div class="card__img-two">
                    <img
                      class=""
                      src="assets/images/arrow-left-purple.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
