<section class="headings --internal-pages">
  <h1>
    <span> {{ "about.headerTitle" | translate }}</span>
  </h1>
</section>

<section class="section-about">
  <div class="container">
    <div class="row align-items-center">
      <div *ngFor="let person of teams" class="col-6 col-md-4 col-lg-3">
        <div class="section-about__item">
          <div class="icon" id="{{ person.id }}">
            <img src="assets/images/{{ person.icon }}" alt="" />
          </div>
          <h3 [innerHTML]="person.name"></h3>
          <h4 [innerHTML]="person.position | translate"></h4>
          <a href="mailto:{{ person.email }}" [innerHTML]="person.email"></a>
        </div>
      </div>
    </div>
  </div>
</section>
